import React from "react"

const Arrow = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.216"
      height="18.248"
      viewBox="0 0 52.216 18.248"
      className={props.class}
    >
      <g
        fill="none"
        strokeWidth="1"
        data-name="Group 302"
        transform="translate(-1077.5 -2921.146)"
      >
        <path
          d="M0 0L51 0"
          data-name="Line 2"
          transform="translate(1077.5 2930.5)"
        ></path>
        <path
          d="M1120.239 2921.5l8.77 8.77-8.77 8.77"
          data-name="Path 408"
        ></path>
      </g>
    </svg>
)

export default Arrow
